import React from "react";
import { styled } from "../../../../stitches.config";
import { h3 } from "../../../../redesign/commonStyles/Fonts";
import Link from "../../../../components/Link"
import { ReactComponent as ThemeLogo } from '../../../../redesign/assets/innpolandRedesign/footer-inn-empty-gray-light.svg?react';
import { ReactComponent as MainStripes } from '../../../../redesign/assets/innpolandRedesign/main-stripes.svg?react';
import GrayStripes from '../../../../redesign/assets/innpolandRedesign/gray-stripes.png';
import { withThemeContext } from "../../../../redesign/components/StitchesThemeProvider";

export interface Block1x3HeaderProps {
  header: string;
  headerLink?: string;
  isDarkMode?: boolean;
  style?: string;
}

const Block1x3Header: React.FC<Block1x3HeaderProps> = ({
  header,
  headerLink,
  isDarkMode,
  style = "",
}) => {
  return (
    <HeaderWrapper className={"style--" + style}>
      {isDarkMode ?
        <ThemeLogo className="background-logo" />
        :
        <img src={GrayStripes} className="background-logo-light" />
      }
      <TitleWrapper>
        <MainStripes />
        {headerLink ?
          <Link to={headerLink} className={h3()}>{header}</Link>
          :
          <h3 className={h3()}>{header}</h3>
        }
      </TitleWrapper>
    </HeaderWrapper>
  );
};


const HeaderWrapper = styled("div", {
  paddingBottom: "0px !important",
  display: "block !important",
  "& h3, a": {
    display: "block",
    color: "$grayscale100",
    paddingLeft: "0px",
    position: "relative",
    margin: "0",
    "@bp4": {
      marginTop: "0 !important",
      overflowX: "hidden",
    },
    // "&:after,&:before": {
    //   content: "",
    //   position: "absolute",
    //   height: "8px",
    //   width: "8px",
    //   borderRadius: "50%",
    //   backgroundColor: "$primary",
    // },
    // "&:after": {
    //   left: "0px",
    //   top: "6px",
    // },
    // "&:before": {
    //   left: "0px",
    //   top: "18px",
    // },
  },
  ".background-logo": {
    position: "relative",
    bottom: "-38px",
    left: "-5px",
    width: "265px",
    opacity: 0.3,
  },
  ".background-logo-light": {
    position: "relative",
    bottom: "-30px",
    left: "-2px",
    width: "250px",
  },
  // kategorie z kolorami 
  "&.style--innpoland-tech": {
    "svg": {
      ".st0inn": {
        fill: "#9500fe"
      }
    }
  },
  "&.style--innpoland-finanse": {
    "svg": {
      ".st0inn": {
        fill: "#ff7200"
      }
    }
  },
  "&.style--innpoland-praca": {
    "svg": {
      ".st0inn": {
        fill: "#ffe600"
      }
    }
  },
  "&.style--innpoland-eco": {
    "svg": {
      ".st0inn": {
        fill: "#01ff0d"
      }
    }
  },
  "&.style--innpoland-biznes": {
    "svg": {
      ".st0inn": {
        fill: "#0067ff"
      }
    }
  },
  "&.style--innpoland-magazyn": {
    "svg": {
      ".st0inn": {
        fill: "#ff04d0"
      }
    }
  },
})

const TitleWrapper = styled("div", {
  display: "flex",
  alignItems: "center",

  "svg": {
    width: "30px",
    position: "relative",
    zIndex: 2,
  }
})

export default withThemeContext(Block1x3Header);
